<template>
    <v-container fluid>
        <PageHeaderSlot :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
        <v-row>
            <v-col cols="12">
                <v-form ref="dataForm" @submit.prevent="save">
                    <v-card class="mb-8">
                        <v-card-title tag="h1" class="mb-4">Key Visual - GIF</v-card-title>
                        <v-card-text>
                            <ImageUploader 
                                singleImage
                                fileInputKey="upload_key_visual_gif"
                                :imgList.sync="formData.key_visual_gif"
                                uploadText="Upload GIF"
                                acceptFormat="imageGifOnly"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></ImageUploader>
                        </v-card-text>
                    </v-card>

                    <v-card class="mb-8">
                        <div class="d-flex">
                            <v-card-title tag="h1" class="mb-4">Key Visual - Slide Images</v-card-title>
                            <v-btn small depressed color="primary accent-4 ma-4" class="white--text" type="button" :disabled="$store.getters.isLoading" @click="openAddBannerDialog()">Add</v-btn>
                        </div>
                       
                        <v-card-text class="pa-0">
                            <Datatable
                                :tableData="bannerTableData"
                                :tableHeaders="bannerTableHeaders"
                                disablePagination
                                disableFooter
                                disableToolbar
                                disableShadow
                                @view-clicked="openAddBannerDialog($event)"
                                @delete-clicked="handleDeleteBanner($event)"
                            ></Datatable>
                        </v-card-text>
                    </v-card>

                    <v-card class="mb-8">
                        <v-card-title tag="h1" class="mb-4">About InLab</v-card-title>
                        <v-card-text>
                            <QuillEditor editorID="aboutEditor" :html.sync="formData.about_content"></QuillEditor>
                        </v-card-text>
                    </v-card>

                    <v-card class="mb-8">
                        <v-card-title tag="h1" class="mb-4">Introduction Video</v-card-title>
                        <v-card-text>
                            <ImageUploader 
                                singleImage
                                fileInputKey="upload_intro_video"
                                :imgList.sync="formData.intro_video"
                                uploadText="Upload Video"
                                acceptFormat="video"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></ImageUploader>
                        </v-card-text>
                    </v-card>

                    <v-card class="mb-8">
                        <div class="d-flex">
                            <v-card-title tag="h1" class="mb-4">Why InLab</v-card-title>
                            <v-btn small depressed color="primary accent-4 ma-4" class="white--text" type="button" :disabled="$store.getters.isLoading" @click="openAddFeatureDialog()">Add</v-btn>
                        </div>
                       
                        <v-card-text class="pa-0">
                            <Datatable
                                :tableData="featureTableData"
                                :tableHeaders="featureTableHeaders"
                                disablePagination
                                disableFooter
                                disableToolbar
                                disableShadow
                                @view-clicked="openAddFeatureDialog($event)"
                                @delete-clicked="handleDeleteFeature($event)"
                            ></Datatable>
                        </v-card-text>
                    </v-card>

                    <v-card class="mb-8">
                        <v-card-title tag="h1" class="mb-4">Why InLab Image</v-card-title>
                        <v-card-text>
                            <ImageUploader 
                                singleImage
                                fileInputKey="upload_feature_image"
                                :imgList.sync="formData.feature_image"
                                acceptFormat="image"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></ImageUploader>
                        </v-card-text>
                    </v-card>


                    <v-btn depressed color="primary accent-4" class="white--text" type="submit"
                        :loading="$store.getters.isLoading"> Save </v-btn>
                </v-form>
            </v-col>
        </v-row>

        <FormDialog
            ref="bannerFormDialog"
            title="Add Key Visual - Slide images"
            hideToolBar
            :maxWidth="900"
            @submit-clicked="handleBannerDialogConfirm()"
            formRef="bannerForm"
        >
            <v-row class="ma-0">
                <v-col cols="12">
                    <FormInput
                        label="Main Slogan"
                        :fieldValue.sync="bannerForm.mainSlogan"
                    ></FormInput>
                </v-col>
                <v-col cols="12">
                    <FormInput
                        label="Sub Slogan"
                        :fieldValue.sync="bannerForm.subSlogan"
                    ></FormInput>
                </v-col>
                <v-col cols="12">
                    <ImageUploader
                        singleImage
                        fileInputKey="upload_slide_image"
                        :imgList.sync="bannerForm.image"
                        acceptFormat="image"
                    ></ImageUploader>
                </v-col>
            </v-row>
        </FormDialog>

        <FormDialog
            ref="featureFormDialog"
            title="Why InLab"
            hideToolBar
            :maxWidth="900"
            @submit-clicked="handleFeatureDialogConfirm()"
            formRef="featureForm"
        >
            <v-row class="ma-0">
                <v-col cols="12">
                    <FormInput
                        label="Title"
                        :fieldValue.sync="featureForm.title"
                        required
                    ></FormInput>
                </v-col>
                <v-col cols="12">
                    <FormTextarea
                        label="Content"
                        :fieldValue.sync="featureForm.content"
                        required
                    ></FormTextarea>
                </v-col>
            </v-row>
        </FormDialog>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import FormInput from '@/components/form/FormInput.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import Datatable from '@/components/Datatable.vue';
import FormDialog from '@/components/FormDialog.vue';
import QuillEditor from '@/components/QuillEditor.vue';
import FormTextarea from '@/components/form/FormTextarea.vue';

export default {
    name: 'Home',
    components: {
        FormInput,
        ImageUploader,
        Datatable,
        FormDialog,
        QuillEditor,
        FormTextarea
    },
    computed: {
        dispatchUpdateOnChange() {
            return true;
        },
    },
    data: () => ({
        rowCount: 0,
        formData: {
            key_visual_gif: [],
            about_content: '',
            intro_video: [],
            feature_image: [],
        },

        bannerTableData: [],
        bannerForm: {
            mainSlogan: '',
            subSlogan: '',
            image: []
        },

        featureTableData: [],
        featureForm: {
            title: '',
            content: '',
        },
        

        // --- static data
        bannerTableHeaders: [
            { text: 'Image', value: 'preview_image_list', width: 300 },
            { text: 'Main Slogan', value: 'main_slogan' },
            { text: 'Sub Slogan', value: 'sub_slogan' },
            { text: '', value: 'actionViewDelete', width: 240  },
        ],
        featureTableHeaders: [
            { text: 'Title', value: 'title' },
            { text: 'Content', value: 'content' },
            { text: '', value: 'actionViewDelete', width: 240  },
        ],
    }),

    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getHomeData() {
            try {
                const data = await this.$Fetcher.GetHomeData();
                this.$set(this.formData, 'about_content', data.about_content);

                if (this.$validate.DataValid(data.key_visual_image_gif)) {
                    this.formData.key_visual_gif.push(data.key_visual_image_gif);
                }

                if (this.$validate.DataValid(data.intro_video)) {
                    this.formData.intro_video.push(data.intro_video);
                }

                if (this.$validate.DataValid(data.feature_image)) {
                    this.formData.feature_image.push(data.feature_image);
                }

                if (this.$validate.DataValid(data.key_visual_slides)) {
                    data.key_visual_slides.forEach(item => {
                        this.bannerTableData.push({
                            id: this.rowCount,
                            main_slogan: item.slogan, 
                            sub_slogan: item.sub_slogan, 
                            preview_image_list: this.$validate.DataValid(item.image) ? [item.image] : [], 
                        });
                        this.rowCount++;
                    });
                }

                if (this.$validate.DataValid(data.feature_list)) {
                    data.feature_list.forEach(item => {
                        this.featureTableData.push({
                            id: this.rowCount,
                            title: item.title, 
                            content: item.content, 
                        });
                        this.rowCount++;
                    });
                }
               
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async openAddBannerDialog(id) {
            if (this.$store.getters.isLoading) {
                return;
            }

            this.$set(this.bannerForm, 'mainSlogan', '');
            this.$set(this.bannerForm, 'subSlogan', '');
            this.$set(this.bannerForm, 'image', []);
            await this.$refs['bannerFormDialog'].$refs['bannerForm'].reset();

            if (this.$validate.DataValid(id)) {
                const target = this.bannerTableData.find(el => el.id === id);
                if (target) {
                    this.$refs['bannerFormDialog'].setEditId(id);
                    this.$set(this.bannerForm, 'mainSlogan', target.main_slogan);
                    this.$set(this.bannerForm, 'subSlogan', target.sub_slogan);
                    this.$set(this.bannerForm, 'image', target.preview_image_list);
                }
            }

            this.$refs['bannerFormDialog'].show();
        },
        handleBannerDialogConfirm() {
            const id = this.$refs['bannerFormDialog'].getEditId();
            if (this.$validate.DataValid(id)) {
                const target = this.bannerTableData.find(el => el.id === id);
                if (target) {
                    this.$set(target, 'main_slogan', this.bannerForm.mainSlogan);
                    this.$set(target, 'sub_slogan', this.bannerForm.subSlogan);
                    this.$set(target, 'preview_image_list', this.bannerForm.image);
                    this.$store.dispatch('setDataIsUpdated', true);
                }
            } else {
                this.bannerTableData.push({
                    id: this.rowCount,
                    main_slogan: this.bannerForm.mainSlogan, 
                    sub_slogan: this.bannerForm.subSlogan, 
                    preview_image_list: this.bannerForm.image
                });
                this.rowCount++;
                this.$store.dispatch('setDataIsUpdated', true);
            }
            
            this.$refs['bannerFormDialog'].hide();
           
        },
        handleDeleteBanner(id) {
            const targetPos = this.bannerTableData.findIndex(el => el.id === id);
            if (targetPos > -1) {
                this.bannerTableData.splice(targetPos, 1);
                this.$store.dispatch('setDataIsUpdated', true);
            }
        },

        async openAddFeatureDialog(id) {
            if (this.$store.getters.isLoading) {
                return;
            }

            this.$set(this.featureForm, 'title', '');
            this.$set(this.featureForm, 'content', '');
            await this.$refs['featureFormDialog'].$refs['featureForm'].reset();

            if (this.$validate.DataValid(id)) {
                const target = this.featureTableData.find(el => el.id === id);
                if (target) {
                    this.$refs['featureFormDialog'].setEditId(id);
                    this.$set(this.featureForm, 'title', target.title);
                    this.$set(this.featureForm, 'content', target.content);
                }
            }

            this.$refs['featureFormDialog'].show();
        },
        handleFeatureDialogConfirm() {
            const id = this.$refs['featureFormDialog'].getEditId();
            if (this.$validate.DataValid(id)) {
                const target = this.featureTableData.find(el => el.id === id);
                if (target) {
                    this.$set(target, 'title', this.featureForm.title);
                    this.$set(target, 'content', this.featureForm.content);
                    this.$store.dispatch('setDataIsUpdated', true);
                }
            } else {
                this.featureTableData.push({
                    id: this.rowCount,
                    title: this.featureForm.title, 
                    content: this.featureForm.content,
                });
                this.rowCount++;
                this.$store.dispatch('setDataIsUpdated', true);
            }
            
            this.$refs['featureFormDialog'].hide();
        },
        handleDeleteFeature(id) {
            const targetPos = this.featureTableData.findIndex(el => el.id === id);
            if (targetPos > -1) {
                this.featureTableData.splice(targetPos, 1);
                this.$store.dispatch('setDataIsUpdated', true);
            }
        },

        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);
            const valid = await this.$refs['dataForm'].validate();
            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            const keyVisualSlides = this.bannerTableData.map(item => {
                return {
                    image: this.$validate.DataValid(item.preview_image_list) ? item.preview_image_list[0] : '',
                    slogan: item.main_slogan,
                    sub_slogan: item.sub_slogan
                }
            })

            const features = this.featureTableData.map(item => {
                return {
                    title: item.title,
                    content: item.content
                }
            })

            const payload = {
                key_visual_image_gif: this.$validate.DataValid(this.formData.key_visual_gif) ? this.formData.key_visual_gif[0] : '',
                key_visual_slides: keyVisualSlides,
                about_content: this.formData.about_content,
                feature_list: features,
                feature_image: this.$validate.DataValid(this.formData.feature_image) ? this.formData.feature_image[0] : '',
                intro_video: this.$validate.DataValid(this.formData.intro_video) ? this.formData.intro_video[0] : '',
            }

            try {
                await this.$Fetcher.SetHomeData(payload);
                this.$store.dispatch('setDataIsUpdated', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'Saved Successfully',
                    type: 'success',
                    refresh: true,
                    redirect: ''
                });

            } catch (err) {
                this.setDialogMessage({
                    title: "Saved Fail",
                    message: err,
                    isError: true,
                    returnLink: null,
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
    },
    async created() {
        this.$store.dispatch('toggleLoadingPage', true);
        this.getHomeData();
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>
  